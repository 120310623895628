var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isShowInput)?_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_vm._l((_vm.followersList),function(ref){
if ( ref === void 0 ) ref = {};
var id = ref.id; if ( id === void 0 ) id = '';
var picture = ref.picture; if ( picture === void 0 ) picture = '';
var email = ref.email; if ( email === void 0 ) email = '';
return _c('div',{key:id,staticClass:"badge-cirle-container",on:{"mouseover":function($event){_vm.hoverId=id},"mouseleave":function($event){_vm.hoverId=-1}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"badge-cirle-container__avatar",on:{"click":function($event){_vm.access ? _vm.showInputAction() : null}}},on),[_c('AvatarImage',{attrs:{"avatar":_vm.avatars[picture],"avatar-key":picture,"color":id,"size":30}}),(_vm.access)?_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(id === _vm.hoverId),expression:"id === hoverId"}],staticClass:"badge-cirle-container__close-icon",attrs:{"x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.manage({
                id: id,
                criteria: 'delete'
              })}}},on),[_vm._v(" mdi-close-circle ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(email)+" ")])])],1)}),_vm._l(((_vm.lengthOfEmptyFollowers)),function(i){return _c('div',{key:i,staticClass:"circle-icon-wrapper circle-icon-wrapper__followers",class:{ 'ml-0': i === 1 && (_vm.lengthOfEmptyFollowers !== 1) }},[_c('v-icon',{attrs:{"color":"mainGrey","size":"20"},on:{"click":function($event){_vm.access ? _vm.showInputAction() : null}}},[_vm._v(" mdi-account-plus-outline ")])],1)}),(_vm.access)?_c('v-icon',{staticClass:"ml-2 brightness-icon",attrs:{"color":"mainGrey","size":"20"},on:{"click":_vm.showInputAction}},[_vm._v(" mdi-plus ")]):_vm._e()],2):_c('v-row',{staticClass:"ma-0"},[_c('ShareAutocomplete',{staticStyle:{"width":"100%"},attrs:{"attach":false,"placeholder":"Name or email","blur":true,"is-project-members-related":"","search-action":"searchProjectMembersV2","users-initial":_vm.followers},on:{"manageInput":_vm.manageInput,"selected":_vm.selected,"remove":_vm.remove}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }