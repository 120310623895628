<template>
  <div>
    <v-row
      v-if="!isShowInput"
      class="ma-0"
      align="center">
      <div
        v-for="({ id = '', picture = '', email = '' } = {}) in followersList"
        :key="id"
        class="badge-cirle-container"
        @mouseover="hoverId=id"
        @mouseleave="hoverId=-1">
        <v-tooltip top>
          <template #activator="{ on }">
            <div
              class="badge-cirle-container__avatar"
              v-on="on"
              @click="access ? showInputAction() : null">
              <AvatarImage
                :avatar="avatars[picture]"
                :avatar-key="picture"
                :color="id"
                :size="30" />
              <v-icon
                v-show="id === hoverId"
                v-if="access"
                x-small
                class="badge-cirle-container__close-icon"
                @click.stop="manage({
                  id,
                  criteria: 'delete'
                })"
                v-on="on">
                mdi-close-circle
              </v-icon>
            </div>
          </template>
          <span>
            {{ email }}
          </span>
        </v-tooltip>
      </div>
      <div
        v-for="i in (lengthOfEmptyFollowers)"
        :key="i"
        class="circle-icon-wrapper circle-icon-wrapper__followers"
        :class="{ 'ml-0': i === 1 && (lengthOfEmptyFollowers !== 1) }">
        <v-icon
          color="mainGrey"
          size="20"
          @click="access ? showInputAction() : null">
          mdi-account-plus-outline
        </v-icon>
      </div>
      <v-icon
        v-if="access"
        color="mainGrey"
        size="20"
        class="ml-2 brightness-icon"
        @click="showInputAction">
        mdi-plus
      </v-icon>
    </v-row>
    <v-row
      v-else
      class="ma-0">
      <ShareAutocomplete
        :attach="false"
        style="width: 100%"
        placeholder="Name or email"
        :blur="true"
        is-project-members-related
        search-action="searchProjectMembersV2"
        :users-initial="followers"
        @manageInput="manageInput"
        @selected="selected"
        @remove="remove" />
    </v-row>
  </div>
</template>
<script>
import ShareAutocomplete from '@/components/App/AppShareAutocomplete';
import {
  mapState, mapMutations, mapActions,
} from 'vuex';
import RowCommentsFollowers from '@/services/graphql/rowCommentsFollowers';
export default {
  name: 'ManageFollowers',
  components: {
    ShareAutocomplete,
  },
  props: {
    followers: {
      type: Array,
      default: () => [],
    },
    access: {
      type: Boolean,
      default: false,
    },
    tableId: {
      type: String,
      default: null,
    },
    rowId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isShowInput: false,
    hoverId: -1,
  }),
  computed: {
    ...mapState({
      avatars: state => state.avatars,
      activeWorkspaceId: state => state.Workspace.activeWorkspaceId,
    }),
    followersList() {
      return this.followers.filter(follower => follower);
    },
    lengthOfEmptyFollowers() {
      const emptyFollowers = 3 - this.followersListLength;
      return emptyFollowers <= 0 ? 0 : emptyFollowers;
    },
    followersListLength() {
      const { followersList } = this;
      if (!followersList) return 0;
      return followersList.length;
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      handleError: 'handleError',
    }),
    manageInput() {
      this.isShowInput = false;
    },
    showInputAction() {
      this.isShowInput = true;
    },
    selected(selected) {
      if (selected.length !== this.followersListLength) {
        const diff = this.lodash.differenceWith(this.followersList,
          selected, this.lodash.isEqual);
        if (!diff.length) {
          this.manage({
            id: selected[selected.length - 1].id,
            criteria: 'add',
          });
        } else {
          this.manage({
            id: diff[0].id,
            criteria: 'delete',
          });
        }
      }
    },
    manage(data) {
      this.manageFollowers(data);
    },
    async manageFollowers({ id: userId, criteria } = {
    }) {
      this.spinner(true);
      const ACTIONS_MAPPING = {
        delete: 'deleteRowFollower',
        add: 'addRowFollower',
      };
      const { [criteria]: action } = ACTIONS_MAPPING;
      if (!action) return;
      try {
        await RowCommentsFollowers[action]({
          projectId: this.$route.params.id,
          rowId: this.rowId || this.$route.query.rowId,
          userId,
          tableId: this.tableId,
          tableType: 'schedule',
          workspaceId: this.activeWorkspaceId,
        }, criteria);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    remove(id) {
      this.manage({
        id,
        criteria: 'delete',
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .badge-cirle-container {
    position: relative;
    .v-icon {
      cursor: pointer;
    }
    &__close-icon {
      position: absolute;
      left: 20px;
      top: 0;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .circle-icon-wrapper {
    &__followers {
      margin-left: 2px;
      margin-right: 2px;
      background: none;
      border: 1px dashed var(--v-mainGrey-base);
      width: 27px;
      height: 27px;
      &:hover {
        border-style: solid;
        border-color: var(--lightBlack-base);
        .v-icon {
          color: var(--lightBlack-base) !important;
        }
      }
    }
  }
</style>
